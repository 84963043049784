import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import path from 'path';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const PageTwo = lazy(() => import('src/pages/dashboard/two'));
const PageThree = lazy(() => import('src/pages/dashboard/three'));
// USER
const UserAccountPage = lazy(() => import('src/pages/account'));
// INVOICE
const InvoiceGeneralPage = lazy(() => import('src/pages/dashboard/invoice/general'));
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const DraftInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/draft-details'));
const CreatedInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/created-details'));
const AllInvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/all-details'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/created-details'));
const InvoiceCreatedListPage = lazy(() => import('src/pages/dashboard/invoice/created-list'));
// ORDER
const OrderUpsteerListPage = lazy(() => import('src/pages/dashboard/order/upteer-list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
const OrderCreatePage = lazy(() => import('src/pages/dashboard/order/new'));
const OrderEditPage = lazy(() => import('src/pages/dashboard/order/edit'));
// PRODUCT
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductDetailsView = lazy(() => import('src/pages/dashboard/product/details'))
const ProductMappingView = lazy(() => import('src/pages/dashboard/product/mapping'));
// CONNECTION
const ConnectionListPage = lazy(() => import('src/pages/dashboard/connection/list'));
const ConnectionNewPage = lazy(() => import('src/pages/dashboard/connection/new'));
const SPSCommerceCallbackPage = lazy(() => import('src/pages/dashboard/connection/callback'));
// CUSTOMER
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));
// PAYMENT
const PaymentGeneralPage = lazy(() => import('src/pages/dashboard/payment/general'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'app', element: <OverviewEcommercePage /> },
      { path: 'two', element: <PageTwo /> },
      { path: 'Analytics', element: <PageThree /> },
      { path: 'account', element: <UserAccountPage /> },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceGeneralPage />, index: true },
          { path: 'created', element: <InvoiceCreatedListPage /> },
          { path: 'list', element: <InvoiceListPage /> },
          { path: 'all-details/:id', element: <AllInvoiceDetailsPage /> },
          { path: 'created-details/:id', element: <CreatedInvoiceDetailsPage /> },
          { path: 'draft-details/:id', element: <DraftInvoiceDetailsPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderUpsteerListPage />, index: true },
          {path: 'upsteer-list', element: <OrderUpsteerListPage />},
          { path: ':id/:type', element: <OrderDetailsPage /> },
          { path: ':id/edit', element: <OrderEditPage /> },
          { path: 'new', element: <OrderCreatePage /> },
        ],
      },
      {
        path: 'customer',
        children: [
          { element: <CustomerListPage />, index: true },
          {path: 'list', element: <CustomerListPage />},
          {path: 'new', element: <CustomerCreatePage />},
          {path: ':id/edit', element: <CustomerEditPage />},
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'product', element: <ProductListPage /> },
          { path: 'mapping', element: <ProductMappingView />},
          { path: 'details/:sku', element: <ProductDetailsView />}
        ],
      },
      {
        path: 'connection',
        children: [
          { element: <ConnectionListPage />, index: true },
          { path: 'list', element: <ConnectionListPage /> },
          { path: 'new', element: <ConnectionNewPage /> },
          { path: 'callback', element: <SPSCommerceCallbackPage />}
        ],
      },
      { path: 'payment', element: <PaymentGeneralPage /> },
    ],
  },
];
